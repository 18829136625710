/**
 * Class representing a ProXCalculator.
 * @extends Calculator
 */
class ProXCalculator extends Calculator {
  constructor(formWrap, stringElementNames, floatElementNames) {

    if (!formWrap) {
      return [false, 'Need element wrapping form'];
    }
    const calculator = formWrap;
    const form = formWrap.querySelector('#calculator-pro-x form.calculator-page-1');

    const numberStores = document.getElementById('number-stores');
    const numberKiosksPerStore = document.getElementById('number-kiosks-per-store');
    const numberTransactionsPerWeekPerStore = document.getElementById('number-transactions-per-week-per-store');
    const averageBasketSizePerTransaction = document.getElementById('average-basket-size-per-transaction');
    const retailSegment = document.getElementById('retail-segment');

    const calculatorInputs = {
      numberStores,
      numberKiosksPerStore,
      numberTransactionsPerWeekPerStore,
      averageBasketSizePerTransaction,
      retailSegment
    };

    super(form, calculatorInputs, stringElementNames, floatElementNames);

    this.roiOutPutMax = 500;
    this.paybackInMonthsOutputMax = 60;
    this.roiOutPutMaxMessage = "Based on your input your results are greater than a 500% ROI!<br><br>Talk with a Toshiba Representative to identify which Product and Service is the right one for your business. ";
    this.paybackInMonthsOutputMaxMessage = "Based on your input your Payback in months is very low!<br><br>Talk with a Toshiba Representative to identify which Product and Service is the right one for your business. ";
    this.roiOutputMin = 20;
    this.paybackInMonthsOutputMin = 0;
    this.outputMinMesage = "The amount of transactions you are doing may not meet the requirements for our products and services. To learn more about your options talk with a Distributor today!" +
      "<br><br>For additional information on our Products and Services contact us <a href='#'>today</a>!";
    this.resultPage = formWrap.querySelector('.calculator-page-2');
    this.roiPercentageMessageWrap = this.resultPage.querySelector('.calculator__roi');
    this.paybackInMonthsMessageWrap = this.resultPage.querySelector('.calculator__payback');

    if (this.form === null) {
      return [false, 'Cannot find form.'];
    }

    this.totalNumberKiosks = 0;

    this.resultPage.addEventListener('pageLoaded', this.showCalculationResults.bind(this));
  }
	/**
	 * This takes the results of the calculation and displays them on the page.
	 * @summary This takes the results of the calculation and displays them on the page.
	 * @description This takes the results of the calculation and displays them on the page. Display is based on whether the results are within the min and max values.
	 * @returns {void}
	 */
  showCalculationResults() {
    const currentPageValues = this.pageValues[this.currentPage];
    const outOfMinMaxMessage = this.resultPage.querySelector('.calculator__out-of-min-max-message');
		const roiOutput = parseInt(currentPageValues.roiOutput);
		const paybackInMonthsOutput = parseInt(currentPageValues.paybackInMonthsOutput);

    if (roiOutput > this.roiOutPutMax) {
      outOfMinMaxMessage.innerHTML = this.roiOutPutMaxMessage;
      this.currentForwardButtonMessage = 'Get a Detailed Analysis';
      this.showHideRoiPaybackText('hide');
      return;

    } else if (roiOutput < this.roiOutputMin) {
      outOfMinMaxMessage.innerHTML = this.outputMinMesage;
      this.currentForwardButtonMessage = 'Get More Information';
      this.showHideRoiPaybackText('hide');
      return;

    }else if(paybackInMonthsOutput > this.paybackInMonthsOutputMax){
      outOfMinMaxMessage.innerHTML = this.outputMinMesage;
      this.currentForwardButtonMessage = 'Get a Detailed Analysis';
      this.showHideRoiPaybackText('hide');
      return;

    }else if(parseInt(currentPageValues.paybackInMonthsOutput) < this.paybackInMonthsOutputMin){
      outOfMinMaxMessage.innerHTML = this.paybackInMonthsOutputMaxMessage;
      this.currentForwardButtonMessage = 'Get a Detailed Analysis';
      this.showHideRoiPaybackText('hide');
      return;
    }

    outOfMinMaxMessage.innerText = "";
    this.resultPage.querySelector('.js-roi-percentage').innerText = roiOutput + '%';
    this.resultPage.querySelector('.js-payback-in-months').innerText = parseInt(currentPageValues.paybackInMonthsOutput) + ' months';
    this.showHideRoiPaybackText('show');
  }

	/**
	 * Toggle for showing and hiding the ROI and Payback in months messages.
	 * @summary Toggle for showing and hiding the ROI and Payback in months messages.
	 * @description Toggle for showing and hiding the ROI and Payback in months messages.
	 * @returns {void}
   */
  showHideRoiPaybackText(action) {

    if (action === 'show') {
      this.paybackInMonthsMessageWrap.style.display = 'block';
      this.roiPercentageMessageWrap.style.display = 'block';

    } else if (action === 'hide') {
      this.paybackInMonthsMessageWrap.style.display = 'none';
      this.roiPercentageMessageWrap.style.display = 'none';

    }
  }

	/**
	 * This function uses the form values found in this.fieldValues in combination with values from previous years calculations and some business decision values from marketing's csv to get the current years calculated value.
	 * @summary Calculates the current years values.
	 * @description This function uses the form values found in this.fieldValues in combination with values from previous years calculations and some business decision values from marketing's csv this logic is based on.
	 * @returns {object} An object containing {cost, netBenefit, cumulative, discountFlows, dollarBase1, previousYearPercentageOfTransactionsPerYear}
   */
  calculateYear(year, previousYearCumulative, previousYearDollarBase, previousYearPercentageOfTransactionsPerYear) {
    /*
    * Begin benefit calculations
    * */
    // 50% of transactions per year for convenience calc - C:12
    // 40% of transactions per year for specialty calc - L:12

    let percentageOfTransactionsPerYear = (previousYearPercentageOfTransactionsPerYear * .05) + previousYearPercentageOfTransactionsPerYear;

		const percentageBasedOnCalculatorType = this.calculatorFields.retailSegment.value === 'convenience' ? .5 : .4;
		const weekAmount = 52 * percentageBasedOnCalculatorType;

    if (year === 0) {
      percentageOfTransactionsPerYear = this.fieldValues.number_transactions_per_week_per_store * weekAmount;
    }

    if (year === 1) {
      percentageOfTransactionsPerYear = ((this.fieldValues.number_transactions_per_week_per_store * .05) + this.fieldValues.number_transactions_per_week_per_store) * weekAmount;
    }
    // c:13
    const multiplier = this.calculatorFields.retailSegment.value === 'convenience' ? .0175 : .083;

    const percentageOfTransactionsPerYearAdjusted = percentageOfTransactionsPerYear * multiplier * .5;
    // c:14
    const dollarBase1 = year === 0 ? 15 : previousYearDollarBase + (previousYearDollarBase * .02);
    // c:15 benefits - last value was (1-25%) in excel sheet
    const benefit = percentageOfTransactionsPerYearAdjusted * dollarBase1 * this.fieldValues.number_stores * .75;

    this.numberKiosks = parseInt(this.calculatorFields.numberKiosksPerStore.value);
    this.numberStores = parseInt(this.calculatorFields.numberStores.value);
    this.totalNumberKiosks = this.numberKiosks * this.numberStores;

    /*
    * Begin cost calculations
    * */
    const kioskInitialCost = year === 0 ? 5500 * this.totalNumberKiosks : 0;
    const kioskYearlyCost = 200 * this.totalNumberKiosks;
    const cost = kioskInitialCost + kioskYearlyCost;

    /*
    * Begin final calculations
    * */
    const benefitValueForCalculation = year === 0 ? 0 : benefit;
    const netBenefit = benefitValueForCalculation - cost;

    // original calc in excel  (1÷(1+15%))^year number
    const discountFactor = (1 / 1.15) ** year;
    const discountFlows = netBenefit * discountFactor;
    const cumulative = year === 0 ? discountFlows : previousYearCumulative + discountFlows;
    return {cost, netBenefit, cumulative, discountFlows, dollarBase1, percentageOfTransactionsPerYear};
  }

  /**
	 * This loops through a period of 6 years of calculations, tracking some variables across years to get the final ROI and Payback in Months values.
	 * @summary Loops through a period of 6 years of calculations, tracking some variables across years to get the final ROI and Payback in Months values.
	 * @description This loops through a period of 6 years of calculations, tracking some variables across years to get the final ROI and Payback in Months values. The form values come from this.fieldValues (which themselves are set in the calculator.js file). The business decision values come from the marketing's csv this logic is based on. This is called from the calculator.js file.
   * @returns {object} An object containing {roi, paybackInMonths}
   */
  runCalculations() {
    let previousYearCumulative = 0;
    let previousYearDollarBase = null;
    let previousYearPercentageOfTransactionsPerYear = null;
    let totalCost = 0;
    let totalNetBenefit = 0;
    let totalCumulative = 0;
    let paybackInYearsToCumulativePositive = null;
    let paybackInYearsDiscountedFlows = 0;
    let paybackInYearsPreviousYearCumulative = 0;
    let year = 0;
    let totalYearsIncludingInitialCosts = 5;

	  while (totalCumulative < 0 || year < 6) {
      const results = this.calculateYear(year, previousYearCumulative, previousYearDollarBase, previousYearPercentageOfTransactionsPerYear);
      previousYearCumulative = results.cumulative;
      previousYearDollarBase = results.dollarBase1;
      previousYearPercentageOfTransactionsPerYear = results.percentageOfTransactionsPerYear;

      if (results.cumulative > 0 && paybackInYearsToCumulativePositive === null) {
        paybackInYearsToCumulativePositive = year;
        paybackInYearsDiscountedFlows = Math.abs(results.discountFlows);
      }

      if (paybackInYearsToCumulativePositive === null) {
        paybackInYearsPreviousYearCumulative = Math.abs(results.cumulative);
      }

      totalCumulative += results.cumulative;

      // limit our calculation of ROI to a 5 year period allowing estimated payback in months to continue
      if (year <= totalYearsIncludingInitialCosts) {
        totalCost += results.cost;
        totalNetBenefit += results.netBenefit;
      }

      year++;
    }

    const roiOutput = totalNetBenefit + totalCost === 0
	    ? (this.roiOutputMin - 1).toString()
	    : ((totalNetBenefit / totalCost) * 100).toFixed();

    const paybackInMonthsOutput = paybackInYearsToCumulativePositive === null
	    ? (this.paybackInMonthsOutputMax + 1).toString()
	    : ((paybackInYearsToCumulativePositive + (paybackInYearsPreviousYearCumulative / paybackInYearsDiscountedFlows)) * 12).toFixed(1);

    return {roiOutput, paybackInMonthsOutput};
  }

	/**
	 * Set's up field validations for non calculator pages.
	 * @summary Set's up field validations for non calculator pages.
	 * @description Set's up field validations for non calculator pages.
	 * @returns {boolean} True if the page is valid, false if not.
   */
  validateNonCalculatorPage() {

    if (this.currentPageElement !== null && this.currentPageElement.classList.contains('contact-us-form')) {
      // this.currentPageElement at this point needs to be the form
      const contactUsValidate = new ContactUsValidate(this.currentPageElement);
      return contactUsValidate.validateInput();
    }

    return true;
  }
}

const pro_x_calculator = new ProXCalculator(document.getElementById('calculator-pro-x'), ['retail_segment'], ['average_basket_size_per_transaction']);